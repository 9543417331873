import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import SubjectOutlinesError from "../../exceptions/SubjectOutlinesError";
import { ISubjectOutline, SubjectOutlineActionEnum, SubjectOutlineActionTypes } from "./types";

export type ThunkResult<R> = ThunkAction<R, AppState, undefined, SubjectOutlineActionTypes>;

const clearSubjectOutline: ActionCreator<ThunkResult<void>> = () => async (
    dispatch: Dispatch<SubjectOutlineActionTypes>
) => dispatch({ selected: undefined, type: SubjectOutlineActionEnum.SELECT });

const fetchSubjectOutlines: ActionCreator<ThunkResult<Promise<boolean>>> = () => async (
    dispatch: Dispatch<SubjectOutlineActionTypes>,
    getState
) => {
    if (
        getState().subjectOutline.isLoading ||
        getState().subjectOutline.errors ||
        getState().subjectOutline.outlines.length !== 0
    ) {
        return false;
    }

    dispatch({ type: SubjectOutlineActionEnum.FETCH_REQUEST });

    const url = `${document.getElementsByTagName("base")[0].href}api/SubjectOutline`;
    const res = await fetch(url, { credentials: "include" });

    try {
        const data = await res.json();

        if (data.status) {
            const err = new SubjectOutlinesError(data.message, data.status);
            // tslint:disable-next-line: no-console
            console.error(err);
            dispatch({ data: err, type: SubjectOutlineActionEnum.FETCH_ERROR });
            return false;
        }

        dispatch({ data, type: SubjectOutlineActionEnum.FETCH_SUCCESS });
        return true;
    } catch (err) {
        // tslint:disable-next-line: no-console
        console.error(err);
        err.message = `An unhandled error has occurred: [${res.status}] ${res.statusText}.\n\nPlease send a ServiceConnect ticket to the Learning and Teaching Systems team.`;
        // tslint:disable-next-line: no-console
        console.error(err);
        dispatch({ data: err, type: SubjectOutlineActionEnum.FETCH_ERROR });
        return false;
    }
};

const fetchSubjectOutlinesSuccess: ActionCreator<ThunkResult<void>> = (data: ISubjectOutline[]) => async (
    dispatch: Dispatch<SubjectOutlineActionTypes>
) => dispatch({ data, type: SubjectOutlineActionEnum.FETCH_SUCCESS });

const fetchSubjectOutlinesError: ActionCreator<ThunkResult<void>> = (data: Error) => async (
    dispatch: Dispatch<SubjectOutlineActionTypes>
) => dispatch({ data, type: SubjectOutlineActionEnum.FETCH_ERROR });

const selectSubjectOutline: ActionCreator<ThunkResult<void>> = (selected: number) => async (
    dispatch: Dispatch<SubjectOutlineActionTypes>,
    getState
) => dispatch({ selected, type: SubjectOutlineActionEnum.SELECT } as SubjectOutlineActionTypes);

export interface ISubjectOutlineActions {
    clearSubjectOutline: ActionCreator<ThunkResult<void>>;
    fetchSubjectOutlines: ActionCreator<ThunkResult<Promise<boolean>>>;
    fetchSubjectOutlinesSuccess: ActionCreator<ThunkResult<void>>;
    fetchSubjectOutlinesError: ActionCreator<ThunkResult<void>>;
    selectSubjectOutline: ActionCreator<ThunkResult<void>>;
}

export const SubjectOutlinesActions: ISubjectOutlineActions = {
    clearSubjectOutline,
    fetchSubjectOutlines,
    fetchSubjectOutlinesError,
    fetchSubjectOutlinesSuccess,
    selectSubjectOutline
};
