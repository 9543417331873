import { combineReducers } from "redux";
import { subjectOutlineReducer } from "./subjectOutline/reducer";

const rootReducer = combineReducers({
    subjectOutline: subjectOutlineReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const mapStateToProps = ({ subjectOutline }: AppState) => ({
    ...subjectOutline,
});
