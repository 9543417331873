// Inst-UI
import { theme } from "@instructure/canvas-theme";
// Deps
import { createBrowserHistory } from "history";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
// Components
import App from "./App";
// Store
import { configureStore } from "./configureStore";

theme.use({});

const baseUrl: string = document.getElementsByTagName("base")[0].getAttribute("href")!;
const history = createBrowserHistory({ basename: baseUrl });

const initialState = (window as any).initialReduxState;
const store = configureStore(history, initialState);

const rootElement = document.getElementById("root");

ReactDOM.render(<CookiesProvider><App store={store} history={history} /></CookiesProvider>, rootElement);
