import { Alert } from "@instructure/ui-alerts";
import React, { Component } from "react";

// tslint:disable: max-line-length
class StaffAlert extends Component {
    public render() {
        return (
            <Alert
                variant="info"
                renderCloseButtonLabel="Close"
                onDismiss={this.storeDismissal}
            >
                Note: Some Subject Outlines may be flagged as Unpublished.<br />
                Where possible, students will always see the published Subject Outline that best matches their enrolment, or a published Standard Subject Outline if one is available.<br />
                For more information, please refer to the <a href="https://lx.uts.edu.au/resources/subject-outline-guide/" title="Subject Outline guide" target="_blank" rel="noopener noreferrer">Subject Outline guide</a>.
            </Alert>
        );
    }

    private storeDismissal() {
        localStorage.setItem("so-alert-dismissed", "true");
    }
}

export default StaffAlert;
