import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route } from "react-router";
import { Store } from "redux";
import Home from "./components/Home/Home";
import { AppState } from "./store";

interface IAppProps {
    store: Store<AppState>;
    history: History;
}

class App extends Component<IAppProps> {
    public render() {
        return (
            <Provider store={this.props.store}>
                <ConnectedRouter history={this.props.history}>
                    <Route path="/:role?" exact component={Home} />
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default App;
