// Inst-UI components
import { View } from "@instructure/ui-layout";
import { Spinner } from "@instructure/ui-spinner";
// React
import React, { Component } from "react";
import { connect } from "react-redux";
import { ActionCreator } from "redux";
import { ThunkDispatch } from "redux-thunk";
// Store
import { mapStateToProps } from "../../store";
import { SubjectOutlinesActions, ThunkResult } from "../../store/subjectOutline/actions";
import { ISubjectOutlineState } from "../../store/subjectOutline/types";
// Components
import EmbeddedOutline from "../EmbeddedOutline/EmbeddedOutline";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import OutlineListItem from "./OutlineListItem";
import "./style.css";

interface IDispatchActions {
    fetchSubjectOutlines: ActionCreator<ThunkResult<void>>;
    selectSubjectOutline: ActionCreator<ThunkResult<void>>;
}

type AllProps = ISubjectOutlineState & IDispatchActions & { role?: string };

class OutlineList extends Component<AllProps> {
    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public ensureDataFetched() {
        this.props.fetchSubjectOutlines();
    }

    public selectOutline(i: number) {
        this.props.selectSubjectOutline(i);
    }

    public render() {
        return (
            <View as="div" textAlign="center">
                {this.renderOutlines()}
            </View>
        );
    }

    private renderOutlines() {
        // Show error panel if an error was returned
        if (this.props.errors) {
            return <ErrorPanel />;
        }

        // Show the selected or only returned outline
        if (this.props.selected !== undefined || this.props.outlines.length === 1) {
            const outline = this.props.selected || this.props.outlines[0];
            return <EmbeddedOutline outline={outline} />;
        }

        // Show the loading spinner if still fetching
        if (this.props.isLoading) {
            return (
                <Spinner renderTitle="Loading Subject Outlines" size="medium" />
            );
        }

        if (this.props.role === "student") {
            if (this.props.publishedOutlines.length === 1) {
                const outline = this.props.publishedOutlines[0];
                return <EmbeddedOutline outline={outline} />;
            }

            return this.props.publishedOutlines.map((o, i) => (
                <OutlineListItem
                    key={i}
                    outline={o}
                    index={i}
                    clickFunc={this.selectOutline.bind(this)}>
                </OutlineListItem>
            ));
        }

        return this.props.outlines.map((o, i) => (
            <OutlineListItem
                key={i}
                outline={o}
                index={i}
                clickFunc={this.selectOutline.bind(this)}>
            </OutlineListItem>
        ));
    }
}

export default connect(
    mapStateToProps,
    (dispatch: ThunkDispatch<{}, {}, any>) => ({
        fetchSubjectOutlines: () => dispatch(SubjectOutlinesActions.fetchSubjectOutlines()),
        selectSubjectOutline: (i: number) =>
            dispatch(SubjectOutlinesActions.selectSubjectOutline(i))
    }),
)(OutlineList);
