import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { AppState } from "./store";
import { subjectOutlineReducer } from "./store/subjectOutline/reducer";

export function configureStore(history: History<any>, initialState: AppState) {
    const middleware = [thunk, routerMiddleware(history)];

    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === "development";
    if (isDevelopment && typeof window !== "undefined" && (window as any).devTooolsExtension) {
        enhancers.push((window as any).devTooolsExtension());
    }

    const rootReducer = combineReducers({
        router: connectRouter(history),
        subjectOutline: subjectOutlineReducer,
    });

    return createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware),
            ...enhancers,
        ),
    );
}
