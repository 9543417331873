import React, { Component } from "react";
import { connect } from "react-redux";

import { Heading } from "@instructure/ui-heading";

import { mapStateToProps } from "../../store";
import { ISubjectOutlineState } from "../../store/subjectOutline/types";

type AllProps = ISubjectOutlineState;

class Header extends Component<AllProps> {
    public render() {
        return (
            <Heading level="h1" margin="none none medium none">
                {this.generateHeadingText()}
            </Heading>
        );
    }
    private generateHeadingText() {
        if (this.props.selected || this.props.outlines.length === 1) {
            const o = this.props.selected || this.props.outlines[0];

            const location = o.location !== "U" ? o.locationName : null;
            const attendance = o.attendanceMode !== "INT" ? o.attendanceModeName : null;

            let suffix = "";
            if (location && attendance) {
                suffix = `(${location}, ${attendance})`;
            } else if (location) {
                suffix = `(${location})`;
            } else if (attendance) {
                suffix = `(${attendance})`;
            }
            // tslint:disable-next-line: max-line-length
            return `Subject Outline - ${o.studyPackageCode} ${o.year} ${o.sessionName} ${suffix}`;
        }

        return "Subject Outlines";
    }
}

export default connect(mapStateToProps)(Header);
