import React, { Component } from "react";

import { Billboard } from "@instructure/ui-billboard";
import { IconTroubleLine } from "@instructure/ui-icons";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store";
import { ISubjectOutlineState } from "../../store/subjectOutline/types";

type AllProps = ISubjectOutlineState;

class ErrorPanel extends Component<AllProps> {
    public render() {
        const message = this.props.errors || "No details provided";
        return (
            <Billboard
                hero={<IconTroubleLine />}
                size="large"
                as="div"
                heading="Sorry, a technical error has occurred"
                message={message}
            />
        );
    }
}

export default connect(mapStateToProps)(ErrorPanel);
