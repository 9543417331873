export interface ISubjectOutline {
    studyPackageCode: string;
    session: string;
    sessionName: string;
    year: string;
    location: string;
    attendanceMode: string;
    url: string;
    published: boolean;
}

export interface IProblemDetail {
    status: number;
    title: string;
    detail: string;
}

enum LocationStrings {
    U = "City",
    CN = "China",
    HK = "Hong Kong",
    SG = "Singapore"
}

enum AttendModeStrings {
    INT = "Standard mode",
    EXT = "Distance mode",
    BLC = "Block mode"
}

export class SubjectOutline {
    public get locationName() {
        return LocationStrings[this.location as keyof typeof LocationStrings];
    }

    public get attendanceModeName() {
        return AttendModeStrings[this.attendanceMode as keyof typeof AttendModeStrings];
    }

    public get parameterisedUrl() {
        return `${this.url}#view=FitH`;
    }

    public studyPackageCode: string;
    public session: string;
    public sessionName: string;
    public year: string;
    public location: string;
    public attendanceMode: string;
    public url: string;
    public published: boolean;

    constructor(so: ISubjectOutline) {
        this.studyPackageCode = so.studyPackageCode;
        this.session = so.session;
        this.sessionName = so.sessionName;
        this.year = so.year;
        this.location = so.location;
        this.attendanceMode = so.attendanceMode;
        this.url = so.url;
        this.published = so.published;
    }
}

export type ApiResponse = ISubjectOutline[];

export enum SubjectOutlineActionEnum {
    FETCH_REQUEST = "SUBJECT_OUTLINE_FETCH_REQUEST",
    FETCH_SUCCESS = "SUBJECT_OUTLINE_FETCH_SUCCESS",
    FETCH_ERROR = "SUBJECT_OUTLINE_FETCH_ERROR",
    SELECT = "SUBJECT_OUTLINE_SELECTED"
}

export interface ISubjectOutlineState {
    isLoading: boolean;
    outlines: SubjectOutline[];
    publishedOutlines: SubjectOutline[];
    unpublishedOutlines: SubjectOutline[];
    selected?: SubjectOutline;
    errors?: string;
    navigationShouldDisplay: boolean;
}

interface IFetchRequestAction {
    type: typeof SubjectOutlineActionEnum.FETCH_REQUEST;
}

interface IFetchSuccessAction {
    type: typeof SubjectOutlineActionEnum.FETCH_SUCCESS;
    data: ISubjectOutline[];
}

interface IFetchErrorAction {
    type: typeof SubjectOutlineActionEnum.FETCH_ERROR;
    data: Error;
}

interface ISelectSubjectOutlineAction {
    type: typeof SubjectOutlineActionEnum.SELECT;
    selected: number | undefined;
}

export type SubjectOutlineActionTypes =
    | IFetchRequestAction
    | IFetchSuccessAction
    | IFetchErrorAction
    | ISelectSubjectOutlineAction;
