import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "@instructure/ui-buttons";
import { IconArrowOpenStartSolid } from "@instructure/ui-icons";
import { View } from "@instructure/ui-layout";
import { ActionCreator } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { mapStateToProps } from "../../store";
import { SubjectOutlinesActions, ThunkResult } from "../../store/subjectOutline/actions";
import { ISubjectOutlineState } from "../../store/subjectOutline/types";

interface IDispatchProps {
    clearSubjectOutline: ActionCreator<ThunkResult<void>>;
}

type AllProps = ISubjectOutlineState & IDispatchProps;

class Navigation extends Component<AllProps> {
    public render() {
        return this.props.navigationShouldDisplay ? (
            <View as="div" textAlign="start" margin="none none medium none">
                <Button
                    variant="light"
                    onClick={this.goBack.bind(this)}
                    icon={IconArrowOpenStartSolid}
                >
                    Back to Subject Outlines
                </Button>
            </View>
        ) : null;
    }

    private goBack() {
        this.props.clearSubjectOutline();
    }
}

export default connect(
    mapStateToProps,
    (dispatch: ThunkDispatch<{}, {}, any>) => ({
        clearSubjectOutline: () => dispatch(SubjectOutlinesActions.clearSubjectOutline()),
    }),
)(Navigation);
