import React, { Component } from "react";
import { isChrome, isFirefox, isMobile } from "react-device-detect";
import { SubjectOutline } from "../../store/subjectOutline/types";

interface IProps {
    outline: SubjectOutline;
}

export default class EmbeddedOutline extends Component<IProps> {
    public render() {
        const embed = !isMobile && (isChrome || isFirefox);
        return embed ? (
            <iframe
                className="outlineEmbed"
                src={`${this.props.outline.parameterisedUrl}`}
                title="Subject Outline Document"
            />
        ) : (
            <a
                href={`${this.props.outline.url}`}
                download
                target="_blank"
                rel="noopener noreferrer"
                title="Download your Subject Outline">
                Download your Subject Outline
            </a>
        );
    }
}
