import { Button } from "@instructure/ui-buttons";
import { IconWarningLine } from "@instructure/ui-icons";
import { Flex } from "@instructure/ui-layout";
import { Text } from "@instructure/ui-text";
import React, { Component } from "react";
import { SubjectOutline } from "../../store/subjectOutline/types";

interface IProps {
    outline: SubjectOutline;
    index: number;
    clickFunc(i: number): void;
}

class OutlineListItem extends Component<IProps> {
    public render() {
        const o = this.props.outline;
        const location = o.location !== "U" ? o.locationName : null;
        const attendance = o.attendanceMode !== "INT" ? o.attendanceModeName : null;

        let suffix = "";
        if (location && attendance) {
            suffix = `(${location}, ${attendance})`;
        } else if (location) {
            suffix = `(${location})`;
        } else if (attendance) {
            suffix = `(${attendance})`;
        }

        return (
            <Button
                as="div"
                variant="light"
                fluidWidth={true}
                margin="medium auto"
                size="large"
                onClick={this.props.clickFunc.bind(null, this.props.index)}
            >
                <Flex justifyItems="space-between">
                    <Flex.Item shouldGrow>
                        Subject Outline - {o.studyPackageCode} {o.year} {o.sessionName} {location} {suffix}
                    </Flex.Item>
                    {!o.published ?
                        <Flex.Item>
                            <IconWarningLine /> <Text size="medium">Unpublished</Text>
                        </Flex.Item> : false}
                </Flex>
            </Button>
        );
    }
}

export default OutlineListItem;
