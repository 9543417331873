import {
    ISubjectOutlineState,
    SubjectOutline,
    SubjectOutlineActionEnum,
    SubjectOutlineActionTypes,
} from "./types";

const initialState: ISubjectOutlineState = {
    isLoading: false,
    navigationShouldDisplay: false,
    outlines: [],
    publishedOutlines: [],
    unpublishedOutlines: []
};

export function subjectOutlineReducer(
    state = initialState,
    action: SubjectOutlineActionTypes,
): ISubjectOutlineState {
    switch (action.type) {
        case SubjectOutlineActionEnum.FETCH_REQUEST:
            return { ...state, isLoading: true };
        case SubjectOutlineActionEnum.FETCH_SUCCESS:
            const outlines = action.data.map((o) => new SubjectOutline(o));
            return {
                ...state,
                isLoading: false,
                outlines,
                publishedOutlines: outlines.filter((o) => o.published),
                unpublishedOutlines: outlines.filter((o) => !o.published)
            };
        case SubjectOutlineActionEnum.FETCH_ERROR:
            return { ...state, isLoading: false, errors: action.data.message };
        case SubjectOutlineActionEnum.SELECT:
            return action.selected !== undefined
                ? {
                    ...state,
                    isLoading: false,
                    navigationShouldDisplay: state.outlines.length > 1,
                    selected: state.outlines[action.selected],
                }
                : {
                    ...state,
                    isLoading: false,
                    navigationShouldDisplay: false,
                    selected: undefined,
                };
        default:
            return state;
    }
}
