// Inst-UI
import { View } from "@instructure/ui-layout";
// React
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
// Components
import Header from "../Header/Header";
import Navigation from "../Navigation/Navigation";
import OutlineList from "../OutlineList/OutlineList";
import StaffAlert from "../StaffAlert/StaffAlert";

type Props = RouteComponentProps<{ role: string }>;

class Home extends Component<Props> {
    public render() {
        return this.props.match.params.role === "staff" ? this.renderStaff() : this.renderDefault();
    }

    private renderStaff() {
        const dismissed = localStorage.getItem("so-alert-dismissed");
        return (
            <View as="div">
                <Navigation />
                <Header />
                {dismissed !== "true" ? <StaffAlert /> : ""}
                <OutlineList role={this.props.match.params.role}/>
            </View>
        );
    }

    private renderDefault() {
        return (
            <View as="div">
                <Navigation />
                <Header />
                <OutlineList role={this.props.match.params.role}/>
            </View>
        );
    }
}

export default Home;
